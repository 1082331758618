body {
  background-color: #F5F6FA; }

a {
  cursor: pointer; }

.card {
  -webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1); }

.flex-shrink, .form-inline .form-group.flex-shrink, .form-inline .form-group input.form-control.flex-shrink {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: flex;
  flex-grow: 1;
  flex-shrink: 1; }

.form-inline .form-group.flex-shrink {
  max-width: 168px; }

.form-inline .form-group input.form-control.flex-shrink {
  width: 100%; }

.form-margin {
  margin-top: 30px; }

.section-title {
  font-weight: bold;
  margin: 30px 0 12px 0; }

.form-section-title label {
  margin: 0; }

.plan-form {
  justify-content: space-evenly; }

.plan-card {
  text-align: center; }

.plan-card-body {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.card-title {
  font-size: 16px; }

.plan-feature-item {
  color: silver;
  font-size: 13px;
  margin: 3px 0; }

button.unstyled {
  display: inline;
  border: none;
  padding: 0;
  margin: 0;
  text-decoration: none;
  background: none;
  color: inherit;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none; }

button.unstyled:hover, button.unstyled:focus {
  background: none; }

button.unstyled:focus {
  outline: 1px solid silver;
  outline-offset: -4px; }

button.unstyled:active {
  transform: scale(0.99); }

.loading-wrapper {
  flex: 1;
  /* position: relative; */ }

.loading-children-wrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  z-index: 1; }

.loading-overlay {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 0; }

.loading-overlay.hiding {
  opacity: 0.01; }

.loading-overlay.hidden {
  display: none;
  opacity: 0; }

.app-loader.app-loader__loading {
  opacity: 0;
  transition: opacity 300ms; }

.app-loader.app-loader__loaded {
  opacity: 1;
  transition: opacity 300ms; }

/*Temporary Fixes*/
.navbar-toggler {
  border-color: transparent !important; }

.flex-end {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: flex;
  justify-content: flex-end; }

.attribute-name {
  float: left; }

.attribute-value {
  float: right; }

/*TEMPORARY STYLING FOR TABLE BACKGROUND ( EVERY OTHER ROW )*/
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.03); }

svg {
  height: auto;
  width: 100%; }

/* Temporary Fix for ToolTip Colors*/
.rc-tooltip-inner {
  background-color: white;
  color: #000; }

.rc-tooltip-arrow {
  border-bottom-color: white !important; }

.ReactTable .rt-thead.-header {
  box-shadow: none; }

.clickable {
  cursor: pointer; }

.ReactTable .-pagination {
  justify-content: flex-end;
  padding-right: 20px; }

.ReactTable .-pagination .-btn {
  height: unset; }

.ReactTable .-pagination .-next {
  flex: 0; }

.ReactTable .-pagination .-center {
  flex: 0;
  flex-wrap: nowrap !important; }

.ReactTable .-pagination .-previous {
  flex: 0; }

.carousel {
  height: 90vh; }

.carousel .slide {
  background: none !important; }

.carousel .slider-wrapper {
  height: 100vh;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  justify-content: center; }

.control-dots {
  padding-left: 0px; }

.text-align-center {
  text-align: center; }

.twitter-picker {
  box-shadow: none !important; }

.centerChildren {
  display: flex;
  justify-content: center;
  align-items: center; }
